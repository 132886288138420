import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from "../components/Navbar/Navbar"
import UslugiPages from "../components/Uslugi/UslugiPages"
import {BoxTextPages, BigTitlePages,  Flex, FlexContent, TitleServices, ListServices} from '../components/Uslugi/UslugiStyled'
import { StaticImage }from "gatsby-plugin-image"

import "../components/layout.css"
import { theme } from "../components/theme.js";
import { ThemeProvider } from "styled-components"

import SEO from "../components/seo"

const chirurgia = () => {
  return (

    <>
    <SEO title="Chirurgia" 
    description="Oferujemy szeroki wachlarz usług weterynaryjnych obejmujący zabiegi chirurgiczne: sterylizacje i kastracje, cięcia cesarskie, ortopedię, leczenie operacyjne psów, kotów, zwierząt futerkowych i gryzoni. 
     Jeżeli szukasz specjalisty lekarza weterynarii w Kaliszu - zapraszamy do Lecznicy dla Zwierząt znajdującej się w Kaliszu przy ul. Lipowej 17-21." 
     keywords="chirurgia zwierząt kalisz, ortopedia zwierząt kalisz" 
     />

    <ThemeProvider theme={theme}>
    
    <Navbar />
    <BigTitlePages style={{justifyContent: 'center'}}
         whileInView={{y: 0, opacity:1}}
         initial={{ y:50, opacity:0}}
         transition={{delay:0.25, duration: 0.75}}
         >Chirurgia</BigTitlePages>  
       
         <FlexContent>
          <UslugiPages />
 <Flex>


    <BoxTextPages>

<TitleServices>Zabiegi chirurgiczne</TitleServices>
<ListServices> Sterylizacje i kastracje, cięcia cesarskie</ListServices>
<ListServices> Chirurgia miękka narządów wewnętrznych
</ListServices>
<ListServices> Leczenie przypadków pourazowych</ListServices>
<ListServices> Ortopedia</ListServices>
<ListServices> Diagnozowanie i leczenie chorób stawów, kości oraz kręgosłupa</ListServices>
<ListServices> Leczenie operacyjne i nieoperacyjne złamań kości i zwichnięć</ListServices>
<ListServices> Chirurgia kostna metodami wewnętrznej i zewnętrznej stabilizacji</ListServices>
<ListServices> Operacyjne leczenie dysplazji
</ListServices>
<ListServices> Chirurgia onkologiczna</ListServices>
<ListServices> Chirurgia plastyczna - opracowywanie ran i wad wrodzonych (korekcje powiek, podniebienia, warg, zewnętrznych narządów płciowych, małżowin usznych ).</ListServices>




    </BoxTextPages>

<StaticImage
    src="../images/chirurgia.jpeg"
    alt="Chirurgia zwierząt Kalisz"
    width={700}
    placeholder="blurred"
    className='staticimagepages'
    />
 
    
</Flex>
</FlexContent>
<Footer />
</ThemeProvider>
    </>
  )
}

export default chirurgia